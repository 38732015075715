import Auth from 'layouts/auth';
import LoginComponent from 'components/auth/Login';

function Login() {
  return (
    <Auth>
      <LoginComponent />
    </Auth>
  );
}

export default Login;
